/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-p5lxmv --full" style={{"paddingTop":0,"paddingBottom":0}} name={"uvod"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7282f2a2e5094cd48b82b949481b9c2b_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7282f2a2e5094cd48b82b949481b9c2b_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7282f2a2e5094cd48b82b949481b9c2b_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7282f2a2e5094cd48b82b949481b9c2b_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7282f2a2e5094cd48b82b949481b9c2b_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7282f2a2e5094cd48b82b949481b9c2b_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7282f2a2e5094cd48b82b949481b9c2b_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7282f2a2e5094cd48b82b949481b9c2b_s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"paddingTop":0}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white\">Nikol&nbsp;</span>|&nbsp;<span style=\"color: white\">Orest<br><br>Wedding day<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--80" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"informace"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30 w--300 ls--0" style={{"maxWidth":880}} content={"<span style=\"color: var(--black);\">Ми не уявляємо це свято без вас – близьких та дорогих нам людей! &nbsp;Будемо щасливі розділити з вами радість нашого весілля!<br><br><br><br>&nbsp;| 21.09.2024 | 13:00 | <br>&nbsp;| Chateau St. Havel |\n<br>&nbsp;|&nbsp;Před Nádražím 1/6, Praha 4|</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1gvpylt css-178z7gn" style={{"paddingBottom":594}} name={"informace-2"} layout={"l30"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37832/1686632733a64e659d727b2e3a7c5304_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37832/1686632733a64e659d727b2e3a7c5304_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37832/1686632733a64e659d727b2e3a7c5304_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37832/1686632733a64e659d727b2e3a7c5304_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37832/1686632733a64e659d727b2e3a7c5304_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37832/1686632733a64e659d727b2e3a7c5304_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37832/1686632733a64e659d727b2e3a7c5304_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37832/1686632733a64e659d727b2e3a7c5304_s=3000x_.JPG);
    }
  
background-position: 51% 8%;
    `}>
          
          <ColumnWrap className="column__flex --center el--2 mt--0 flex--center" columns={"2"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"program"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62" style={{"maxWidth":880}} content={"<span style=\"caret-color: rgb(18, 18, 34); color: rgb(18, 18, 34);\"><br>Plan of the day</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-18mw387 --left" name={"program-2"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37832/50fa4ce2d6b946faa195af8811515f74_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37832/50fa4ce2d6b946faa195af8811515f74_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37832/50fa4ce2d6b946faa195af8811515f74_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37832/50fa4ce2d6b946faa195af8811515f74_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37832/50fa4ce2d6b946faa195af8811515f74_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37832/50fa4ce2d6b946faa195af8811515f74_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37832/50fa4ce2d6b946faa195af8811515f74_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37832/50fa4ce2d6b946faa195af8811515f74_s=3000x_.JPG);
    }
  
    `}>
        </Column>


        <Column className="--left pb--80" style={{"paddingTop":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"program-3"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1300}} columns={"1"}>
            
            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-custom-1--95);\">13:00</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Welcome Prosecco &amp; Canapes<br>"}>
              </Text>

              <Image style={{"maxWidth":100}} alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/17edc39d593b4f768700b843c341b22d.svg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-custom-1--95);\">14:00</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Wedding ceremony<br>"}>
              </Text>

              <Image style={{"maxWidth":100}} alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/3cc5b5a96ef04323b543f2b8b4a55fe3.svg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-custom-1--95);\">15:00</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Cocktail hour &amp; Photos<br>"}>
              </Text>

              <Image style={{"maxWidth":100}} alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/51712b7212824833b658ef7cad5f50b9.svg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-custom-1--95);\">17:00</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Wedding dinner<br>"}>
              </Text>

              <Image style={{"maxWidth":100}} alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/29288f33de65415482aafafd30dca7c3.svg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-custom-1--95);\">19:00</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Newlyweds first dance<br>"}>
              </Text>

              <Image style={{"maxWidth":100}} alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/25976468ae274352aeecbf97e79d5cd5.svg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-custom-1--95);\">20:00</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Wedding cake<br>"}>
              </Text>

              <Image style={{"maxWidth":100}} alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/cea5a113adad4656959b69ee3d5fbc2d.svg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-custom-1--95);\">22:00</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Party time!<br>"}>
              </Text>

              <Image style={{"maxWidth":100}} alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/4245602635b04eb1af32c41fbe7bb1e8.svg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-zui62s pt--80" style={{"paddingBottom":457}} name={"obrazek"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7b24081e460a47369e81db69899baa0e_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7b24081e460a47369e81db69899baa0e_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7b24081e460a47369e81db69899baa0e_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7b24081e460a47369e81db69899baa0e_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7b24081e460a47369e81db69899baa0e_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7b24081e460a47369e81db69899baa0e_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7b24081e460a47369e81db69899baa0e_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37832/7b24081e460a47369e81db69899baa0e_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1 pb--80 pt--100 flex--center" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--80" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"polozky"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62" style={{"maxWidth":880}} content={"<span style=\"caret-color: rgb(247, 246, 237); color: var(--black);\">Dress-code</span><br>"}>
              </Title>

              <Image style={{"maxWidth":873}} alt={""} src={"https://cdn.swbpg.com/t/37832/ecdce9f8a90441eb8c30824fadcc4cb7_s=660x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37832/ecdce9f8a90441eb8c30824fadcc4cb7_s=350x_.png 350w, https://cdn.swbpg.com/t/37832/ecdce9f8a90441eb8c30824fadcc4cb7_s=660x_.png 660w, https://cdn.swbpg.com/t/37832/ecdce9f8a90441eb8c30824fadcc4cb7_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"<span style=\"color: var(--black); font-style: italic;\">Дорогі жінки, ми намагалися зробити нашу урочистість красивою, будемо вдячні, якщо Ви підтримаєте кольорову гаму нашого весілля.<br></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":37,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"program"}>
          
          <ColumnWrap className="column__flex --center el--1 pt--12" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43" style={{"maxWidth":1080}} content={"<span style=\"color: var(--color-custom-1--95);\">Будь ласка підтвердіть вашу присутність!</span><br>"}>
              </Title>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"<span style=\"font-style: italic;\">Велике прохання для кожного гостя вказати обмеження \n у виборі страви (алергії, тощо...). <br>&nbsp;Так персонал зможе подбати про вас якнайкраще!</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"program-2"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"backgroundColor":"var(--white)"}} columns={"1"}>
            
            <ColumnWrapper className="pb--20">
              
              <ContactForm className="--shape2 fs--22" style={{"maxWidth":1050}} action={"/contact"} fields={[{"name":"Ім'я та прізвище","type":"text","required":true,"placeholder":""},{"name":"Номер телефону","type":"text","placeholder":""},{"name":"Обмеження у виборі страви","type":"text","placeholder":""},{"name":"Вiдправити","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogalerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37832/b514f0634ccb43c4b25f7e957e7ce718_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37832/b514f0634ccb43c4b25f7e957e7ce718_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37832/b514f0634ccb43c4b25f7e957e7ce718_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37832/d7c6a189fd3c483e9d927436fb2f2039_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37832/d7c6a189fd3c483e9d927436fb2f2039_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37832/d7c6a189fd3c483e9d927436fb2f2039_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37832/bdabda41a42541b3bbacb381b76739a7_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37832/bdabda41a42541b3bbacb381b76739a7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37832/bdabda41a42541b3bbacb381b76739a7_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"mj88hozfje"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"<span style=\"color: var(--color-custom-1--95);\">З усіх питань ви можете звертатися до нашого весільного організатора:</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: var(--color-custom-1--95);\">Elizaveta Volkhina <br>&nbsp;+ 420 775 314 669 <br>&nbsp;Info@brilliantwedding.cz</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80" style={{"paddingTop":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-custom-1--95);\">Будемо на вас чекати!</span><br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"font-style: italic;\">З любов'ю, <br>&nbsp;Ніколь та Орест!</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}